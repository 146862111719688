<template>
  <v-app>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-app-bar elevation="1" app color="white">
      <v-img src="@/assets/SureCredsLogo.png" max-width="190px"></v-img>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
          xs="12"
          align="center"
          justify="center"
        >
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="8" xs="12">
              <v-card class="elevation-0" max-width="550px">
                <v-img src="@/assets/Image7.jpg"></v-img>
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="4" xs="12">
              <v-card class="py-6 mt-12 elevation-0" max-width="400px">
                <v-card-title class="pb-6">
                  <v-row class="text-center">
                    <v-col
                      cols="12"
                      xs="12"
                      sm="12"
                      md="12"
                      class="py-0"
                      align="center"
                    >
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <div class="heading1 PrimaryFontColor text-uppercase">
                        BMS - Sign In
                      </div>
                    </v-col>
                  </v-row>
                </v-card-title>
                <!-- <v-window v-model="step" class="px-3">
                  <v-window-item :value="1"> -->
                <v-window v-model="LoginStepper" touchless>
                  <v-window-item :value="1">
                    <!-- <v-card-text> -->
                    <v-form ref="form">
                      <v-text-field
                        outlined
                        dense
                        label="Email ID *"
                        prepend-inner-icon="mdi-email"
                        :rules="rules.email"
                        v-model="Login.EmailID"
                        class="pa-2"
                      ></v-text-field>
                    </v-form>
                    <v-card-actions class="pt-0 pb-8 px-4">
                      <v-btn
                        block
                        rounded
                        dark
                        class="borderRadius"
                        color="secondary"
                        tile
                        :loading="loading"
                        @click="validate_sign_in('otp')"
                        >Send Otp</v-btn
                      ></v-card-actions
                    >
                  </v-window-item>
                  <v-window-item :value="2">
                    <div class="heading2 text-left blackAndBoldFont">
                      OTP sent to {{ Login.EmailID }}
                      <v-tooltip bottom color="#474747">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-on="on"
                            v-bind="attrs"
                            color="primary"
                            text
                            small
                            dark
                            depressed
                            class="FontSize text-center ml-4"
                            @click="LoginStepper = 1"
                            ><v-icon small class="mt-n1">mdi-pencil</v-icon
                            >Change email ID</v-btn
                          >
                        </template>
                        <span>Wrong Email? Go back and change.</span>
                      </v-tooltip>
                    </div>

                    <!-- <v-text-field
                          outlined
                          dense
                          label="Password *"
                          :type="isPwd ? 'text' : 'password'"
                          @click:append="isPwd = !isPwd"
                          required
                          :append-icon="isPwd ? 'mdi-eye' : 'mdi-eye-off'"
                          prepend-inner-icon="mdi-lock"
                          :rules="[(v) => !!v || 'Password is required']"
                          v-model="Login.Password"
                        ></v-text-field>
                        <v-btn
                          x-small
                          text
                          block
                          align="left"
                          class="FontSize borderRadius"
                          color="primary"
                          @click="step = 3"
                          >Forgot Password ?</v-btn
                        > -->
                    <!-- <div style="max-width: 400px"> -->
                    <v-otp-input
                      v-model="Login.otpInput"
                      ref="formOTP"
                      class="pa-2"
                    ></v-otp-input>
                    <!-- <div
                      :class="
                        countDown <= 10
                          ? 'RedHeader'
                          : countDown <= 20
                          ? 'OrangeHeader'
                          : 'GreenHeader'
                      "
                      :hidden="countDown == 0"
                    >
                      {{ formattedTime }}
                    </div> -->
                    <v-layout align-start justify-start>
                      <v-btn
                        text
                        small
                        color="secondary"
                        depressed
                        dark
                        class="mt-n3"
                        @click="validate_sign_in('otp')"
                      >
                        <!-- <v-icon> mdi-refresh</v-icon> -->
                        Resend OTP
                      </v-btn>
                    </v-layout>
                    <!-- </div> -->
                    <!-- </v-form> -->
                    <!-- </v-card-text> -->
                    <v-card-actions class="pt-2 pb-8 px-4">
                      <!-- <v-btn
                    block
                    rounded
                    dark
                    v-if="otpVisible == 0"
                    class="borderRadius"
                    color="secondary"
                    tile
                    :loading="loading"
                    @click="validate_sign_in('otp')"
                    >Send Otp</v-btn
                  > -->
                      <v-btn
                        block
                        :loading="loading1"
                        tile
                        class="borderRadius"
                        color="secondary"
                        @click="validate_sign_in('login')"
                        >Login</v-btn
                      >
                    </v-card-actions>
                  </v-window-item>
                </v-window>
                <!-- </v-window-item>
                  <v-window-item :value="2">
                    <v-card-text>
                      <v-form ref="formCP" lazy-validation>
                        <v-text-field
                          class="BorderRadius"
                          outlined
                          dense
                          label="Enter New Password *"
                          :type="isPwdNew ? 'text' : 'password'"
                          @click:append="isPwdNew = !isPwdNew"
                          required
                          :append-icon="isPwdNew ? 'mdi-eye' : 'mdi-eye-off'"
                          prepend-inner-icon="mdi-lock"
                          :rules="[(v) => !!v || 'Password is required']"
                          v-model="Password.NewPassword"
                        ></v-text-field>
                        <v-text-field
                          class="BorderRadius"
                          outlined
                          dense
                          label="Confirm New Password *"
                          :type="isPwdConfirm ? 'text' : 'password'"
                          @click:append="isPwdConfirm = !isPwdConfirm"
                          required
                          :append-icon="
                            isPwdConfirm ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          prepend-inner-icon="mdi-lock"
                          :rules="[(v) => !!v || 'Password is required']"
                          v-model="Password.ConfirmPassword"
                        ></v-text-field>
                      </v-form>
                    </v-card-text>
                    <v-card-actions class="pt-0 pb-8 px-4">
                      <v-btn
                        block
                        :loading="loading"
                        tile
                        class="borderRadius"
                        color="primary"
                        @click="ValidateConfirmPasswordForm()"
                        >Login</v-btn
                      >
                    </v-card-actions>
                  </v-window-item>
                  <v-window-item :value="3">
                    <v-card-text>
                      <v-form ref="formOTP">
                        <div class="text-center">
                          Please Enter The Registered Email
                        </div>
                        <v-text-field
                          outlined
                          label="Email"
                          v-model="OTPEmail"
                          :rules="[(v) => !!v || 'Email ID is required']"
                          class="mt-4"
                        >
                        </v-text-field>
                        <div class="FontSize text-center">
                          *Please enter the e-mail used for SureCreds
                          login/registration
                        </div>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text color="primary" small @click="step = 1"
                        >Back To Sign In</v-btn
                      >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" small @click="ValidateOTP()"
                        >Next</v-btn
                      >
                    </v-card-actions>
                  </v-window-item>
                  <v-window-item :value="4">
                    <v-card-text>
                      <v-form ref="formConfirmOTP">
                        <div class="text-center">
                          Enter the SureCreds Activation Code
                        </div>
                        <v-text-field
                          outlined
                          label="Code *"
                          v-model="ConfirmOTP"
                          :rules="[(v) => !!v || 'Code is required']"
                          class="mt-4"
                        >
                        </v-text-field>
                        <v-text-field
                          outlined
                          label="Enter New Password *"
                          v-model="ConfirmNewPassword"
                          :rules="[(v) => !!v || 'Password is required']"
                          class="mt-4"
                          :type="isPwdConfirmCode ? 'text' : 'password'"
                          @click:append="isPwdConfirmCode = !isPwdConfirmCode"
                          required
                          :append-icon="
                            isPwdConfirmCode ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          prepend-inner-icon="mdi-lock"
                        >
                        </v-text-field>
                        <div class="FontSize text-center">
                          Enter the SureCreds Activation Code sent to your
                          registered Email.
                        </div>
                      </v-form>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn text color="primary" small @click="step = 1"
                        >Back To Sign In</v-btn
                      >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        small
                        @click="ValidateEnteredOTP()"
                        >Next</v-btn
                      >
                    </v-card-actions>
                  </v-window-item>
                </v-window> -->
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>
<script>
import { Auth } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  components: {
    Snackbar,
  },
  data: () => ({
    // countDown: 60,
    // timerExpired: false,
    LoginStepper: 1,
    isPwdConfirmCode: false,
    ConfirmNewPassword: "",
    ConfirmOTP: "",
    OTPEmail: "",
    isPwdNew: false,
    isPwdConfirm: false,
    Password: {
      NewPassword: "",
      ConfirmPassword: "",
    },
    loading: false,
    loading1: false,
    SnackBarComponent: {},
    isPwd: false,
    Login: {
      EmailID: "",
      // Password: "",
      otpInput: "",
    },
    rules: {
      email: [
        (v) => !!v || "E-mail is required",
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail";
        },
      ],
    },
    LoginCardBoolean: true,
    ConfirmPasswordCard: false,
    Snackbar: {
      SnackbarVmodel: false,
      SnackbarText: "",
      Color: "",
      Top: true,
    },
    user: {},
  }),
  computed: {
    // formattedTime() {
    //   const minutes = Math.floor(this.countDown / 60);
    //   const seconds = this.countDown % 60;
    //   return `${minutes
    //     .toString()
    //     .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    // },
  },

  // beforeDestroy() {
  //   clearInterval(this.timerInterval);
  // },
  methods: {
    // updateTimer() {
    //   if (this.countDown > 0) {
    //     this.countDown--;
    //   } else {
    //     clearInterval(this.timerInterval);
    //     this.timerExpired = true;
    //   }
    //   // this.checkOTP();
    // },
    checkOTP() {
      if (this.Login.otpInput.length === 6 && !this.timerExpired) {
        clearInterval(this.timerInterval);
        this.timerExpired = true;
      }
    },
    validate_sign_in(val) {
      var valid = true;
      if (this.Login.EmailID == "") {
        valid = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "error",
          SnackbarText: "Email ID  is Required.",
          timeout: 5000,
          Top: true,
        };
      }
      if (valid) {
        if (val == "otp") {
          this.generate_otp();
        } else {
          this.login_with_otp();
        }
      }
    },
    async generate_otp() {
      this.loading = true;
      try {
        await Auth.signIn(this.Login.EmailID)
          .then((user) => {
            this.user = user;

            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              SnackbarText: "OTP Sent Successfully.",
              timeout: 5000,
              Top: true,
            };

            this.LoginStepper = 2;
            this.timerInterval = setInterval(this.updateTimer, 1000);
            this.loading = false;
          })
          .catch(() => {
            if (Auth.sendCustomChallengeAnswer !== true)
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                SnackbarText: "Invalid Email",
                timeout: 5000,
                Top: true,
              };

            this.loading1 = false;
          });
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: error.message,
          timeout: 5000,
          Top: true,
        };

        this.loading1 = false;
      }
    },
    async login_with_otp() {
      this.loading1 = true;
      try {
        await Auth.sendCustomChallengeAnswer(this.user, this.Login.otpInput);
        await Auth.currentAuthenticatedUser()
          .then((response) => {
            console.log("otp response ", response);
            this.$store.commit("SET_USEREMAIL", this.Login.EmailID);

            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              SnackbarText: "Logged in Successfully.",
              timeout: 5000,
              Top: true,
            };
            this.ActivateMethod();
            this.loading1 = false;
          })
          .catch((error) => {
            if (error == "The user is not authenticated") {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                SnackbarText: "Invalid OTP",
                timeout: 5000,
                Top: true,
              };
            }
            this.loading1 = false;
          });
      } catch (error) {
        if (error.message == "Incorrect username or password.") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Your OTP is still incorrect",
          };
          this.loading1 = false;
        } else if (error.message == "Invalid session for the user.") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Invalid session for the user.",
          };
        }
        this.loading1 = false;
      }
    },
    // ValidateSignUpForm() {
    //   if (this.$refs.form.validate()) {
    //     this.SignIn();
    //   } else {
    //     this.SnackBarComponent = {
    //       SnackbarVmodel: true,
    //       SnackbarColor: "red",
    //       Top: true,
    //       SnackbarText: "Invalid Inputs",
    //     };
    //   }
    // },
    // ConfirmOTPMethod() {
    //   Auth.forgotPasswordSubmit(
    //     this.OTPEmail,
    //     this.ConfirmOTP,
    //     this.ConfirmNewPassword
    //   )
    //     .then(() => {
    //       this.$refs.formConfirmOTP.reset();
    //       this.SnackBarComponent = {
    //         SnackbarVmodel: true,
    //         SnackbarColor: "green",
    //         Top: true,
    //         SnackbarText: "Password Successfully Changed ",
    //       };
    //       this.step = 1;
    //     })
    //     .catch((err) => {
    //       this.SnackBarComponent = {
    //         SnackbarVmodel: true,
    //         SnackbarColor: "red",
    //         Top: true,
    //         SnackbarText: err.message,
    //       };
    //     });
    // },
    // ValidateEnteredOTP() {
    //   if (this.$refs.formConfirmOTP.validate()) {
    //     this.ConfirmOTPMethod();
    //   } else {
    //     this.SnackBarComponent = {
    //       SnackbarVmodel: true,
    //       SnackbarColor: "red",
    //       Top: true,
    //       SnackbarText: "Please Enter The Code Sent To Registered Email",
    //     };
    //   }
    // },
    // ValidateOTP() {
    //   if (this.$refs.formOTP.validate()) {
    //     this.SendOTPEmail();
    //   } else {
    //     this.SnackBarComponent = {
    //       SnackbarVmodel: true,
    //       SnackbarColor: "red",
    //       Top: true,
    //       SnackbarText: "Please Enter Registered Email",
    //     };
    //   }
    // },
    // SendOTPEmail() {
    //   Auth.forgotPassword(this.OTPEmail)
    //     .then(() => {
    //       this.SnackBarComponent = {
    //         SnackbarVmodel: true,
    //         SnackbarColor: "green",
    //         Top: true,
    //         SnackbarText: "OTP Sent To Registered Email",
    //       };
    //       this.step = 4;
    //     })
    //     .catch((err) => {
    //       this.SnackBarComponent = {
    //         SnackbarVmodel: true,
    //         SnackbarColor: "red",
    //         Top: true,
    //         SnackbarText: err.message,
    //       };
    //     });
    // },
    // async ConfirmPassword() {
    //   try {
    //     this.loading = true;
    //     const result = await Auth.completeNewPassword(
    //       this.user,
    //       this.Password.ConfirmPassword
    //     )
    //       .then((user) => {
    //         return user;
    //       })
    //       .catch((e) => {
    //         return e;
    //       });
    //     if (result.username) {
    //       this.$refs.formCP.reset();
    //       this.loading = false;
    //       this.SnackBarComponent = {
    //         SnackbarVmodel: true,
    //         SnackbarColor: "green",
    //         Top: true,
    //         SnackbarText: "Login Successful",
    //       };
    //       this.ActivateMethod();
    //     }
    //     this.loading = false;
    //   } catch (error) {
    //     this.loading = false;
    //     this.SnackBarComponent = {
    //       SnackbarVmodel: true,
    //       SnackbarColor: "red",
    //       Top: true,
    //       SnackbarText: error.message,
    //     };
    //   }
    // },
    // ValidateConfirmPasswordForm() {
    //   if (this.$refs.formCP.validate()) {
    //     if (this.Password.NewPassword == this.Password.ConfirmPassword) {
    //       this.ConfirmPassword();
    //     } else {
    //       this.$refs.form.reset();
    //       this.SnackBarComponent = {
    //         SnackbarVmodel: true,
    //         SnackbarColor: "red",
    //         Top: true,
    //         SnackbarText: "Password does not match",
    //       };
    //     }
    //   } else {
    //     this.SnackBarComponent = {
    //       SnackbarVmodel: true,
    //       SnackbarColor: "red",
    //       Top: true,
    //       SnackbarText: "Fields marked with asterisks (*) are mandatory",
    //     };
    //   }
    // },
    // ValidateLoginForm() {
    //   if (this.$refs.form.validate()) {
    //     this.SignIn();
    //   } else {
    //     this.SnackBarComponent = {
    //       SnackbarVmodel: true,
    //       SnackbarColor: "red",
    //       Top: true,
    //       SnackbarText: "Fields marked with asterisks (*) are mandatory",
    //     };
    //   }
    // },
    // async SignIn() {
    //   try {
    //     this.loading = true;
    //     const user = await Auth.signIn(this.Login.EmailID, this.Login.otpInput);
    //     this.user = user;
    // if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
    //   this.step = 2;
    //   this.loading = false;
    //   this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
    // } else {
    //     this.$store.commit("SET_USEREMAIL", this.Login.EmailID);
    //     this.SnackBarComponent = {
    //       SnackbarVmodel: true,
    //       SnackbarColor: "green",
    //       Top: true,
    //       SnackbarText: "Login Successful",
    //     };
    //     this.ActivateMethod();
    //     this.loading = false;
    //     // }
    //   } catch (error) {
    //     this.loading = false;
    //     this.SnackBarComponent = {
    //       SnackbarVmodel: true,
    //       SnackbarColor: "red",
    //       Top: true,
    //       SnackbarText: error.message,
    //     };
    //   }
    // },
    ActivateMethod() {
      setTimeout(() => this.$router.push("/Issuer"), 500);
    },
  },
};
</script>
